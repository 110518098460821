import React from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Grid,
  TextField,
  Typography,
  Link,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@material-ui/core"
import { Button } from "instadrops-ui"
import { navigate } from "gatsby"
import { callAnalyticsEvent, validatePassword } from "../utils"
import "./styles.css"
import { BACKEND_AUTH_ROUTES, LOGIN_ERROR_MESSAGES } from "../utils/consts"
import Alert from "@material-ui/lab/Alert"
import { isEmail } from "validator"
import axios from "axios"
import EmailVerifiedMessage from "./EmailVerifiedMessage"
import { ArrowBack } from "@material-ui/icons"
import jwtDecode from "jwt-decode"

const RegisterForm = () => {
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      passwordConfirm: "",
    },
  })

  const [errorCode, setErrorCode] = React.useState(0)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  const [successVerify, setSuccessVerify] = React.useState(false)
  const [invitationRegister, setInvitationRegister] = React.useState(false)
  const [invitationRegisterEmail, setInvitationRegisterEmail] =
    React.useState("")
  const formState = watch()

  const isButtonDisabled =
    !formState.password.length > 0 || !formState.passwordConfirm.length > 0

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      callAnalyticsEvent("view_register_page")
      const token = new URLSearchParams(window.location.search).get("token")
      if (token) {
        setLoading(true)

        axios
          .post(BACKEND_AUTH_ROUTES.verify, {
            token: token,
          })
          .then((data) => {
            setLoading(false)
            setSuccessVerify(true)
            localStorage.setItem("token", token)
            callAnalyticsEvent("verify_email.success")
            callAnalyticsEvent("start_trial.success")
            navigate("/chat")
          })
          .catch(() => {
            setLoading(false)
            setOpenSnackbar(true)
            setErrorCode(1102)
            callAnalyticsEvent("verify_email.error")
          })
      }
    }
  }, [])

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      callAnalyticsEvent("view_register_page")
      const invitationToken = new URLSearchParams(window.location.search).get(
        "invitationToken"
      )
      try {
        const decodedToken = jwtDecode(invitationToken)

        if (invitationToken && decodedToken?.type === "accountInvite") {
          axios
            .post(BACKEND_AUTH_ROUTES.verifyToken, {
              token: invitationToken,
            })
            .then(({ data }) => {
              if (data?.success) {
                setInvitationRegister(true)
                setInvitationRegisterEmail(decodedToken?.email)
              }
            })
            .catch(() => {
              navigate("/login")
            })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [])

  const onSubmit = () => {
    callAnalyticsEvent("comlete_register_form.init")
    setLoading(true)
    handleRegister()
  }

  const handleRegister = () => {
    const invitationToken = new URLSearchParams(window.location.search).get(
      "invitationToken"
    )
    const payload = {
      token: invitationToken,
      password: formState.password,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }

    fetch(BACKEND_AUTH_ROUTES.register, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Get valid login token from response, set to local storage, go to application
        const { success, code, token } = data
        if (success) {
          localStorage.setItem("token", token)
          navigate("/chat")
          callAnalyticsEvent("comlete_register_form.success")
          callAnalyticsEvent("send_register_verification_email")
        } else {
          setErrorCode(code || 0)
          setOpenSnackbar(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        const { response } = err
        callAnalyticsEvent("comlete_register_form.error")
        setOpenSnackbar(true)
        setLoading(false)
        setErrorCode(0)
      })
  }

  // show how many  character are expected label validation

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      style={{ height: "90vh" }}
    >
      <Grid item xs={12} md={7} container>
        <IconButton onClick={() => navigate("/")}>
          <ArrowBack />
        </IconButton>
        <Typography style={{ userSelect: "none", marginTop: 14 }}>
          Volver al inicio
        </Typography>
      </Grid>

      <Grid item xs={8} container justify="center">
        <Typography variant="h4" style={{ fontWeight: 800 }}>
          Alchtec.com
        </Typography>
      </Grid>
      <Grid item xs={8} container justify="center">
        <Typography>Registro de usuario</Typography>
      </Grid>
      {!loading ? (
        successVerify ? (
          <EmailVerifiedMessage />
        ) : !emailSent && invitationRegisterEmail ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container justify="center">
              <Grid item xs={8}>
                <Grid container>
                  <Grid item style={{ padding: 12 }} xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      disabled={invitationRegister}
                      rules={{
                        required: "Email is required",
                        validate: (value) =>
                          isEmail(value) ||
                          "Por favor ingresa un correo electrónico válido",
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Email"
                          id="outlined-size-small"
                          variant="outlined"
                          size="small"
                          error={!!errors.email}
                          value={invitationRegisterEmail}
                          helperText={errors.email && errors.email.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid style={{ padding: 12 }} item xs={12}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Se necesita una contraseña.",
                        validate: (value) =>
                          validatePassword(value) ||
                          "Por favor ingresa una contraseña valida. (Mínimo 10 caracteres, incluyendo al menos una mayúscula y un número)",
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Contraseña"
                          id="outlined-size-small"
                          variant="outlined"
                          size="small"
                          type="password"
                          error={!!errors.password}
                          helperText={
                            (errors.password && errors.password.message) ||
                            "Mínimo 10 caracteres, incluyendo al menos una mayúscula y un número"
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid style={{ padding: 12 }} item xs={12}>
                    <Controller
                      name="passwordConfirm"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Confirma tu contraseña.",
                        validate: (value) => {
                          return (
                            formState.password === value ||
                            "Ambas contraseñas deben ser iguales."
                          )
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Confirmar Contraseña"
                          id="outlined-size-small"
                          variant="outlined"
                          size="small"
                          type="password"
                          error={!!errors.passwordConfirm}
                          helperText={
                            (errors.passwordConfirm &&
                              errors.passwordConfirm.message) ||
                            "Ambas contraseñas deben ser iguales"
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ padding: 12 }}>
                    <Typography>
                      ¿Ya tienes usuario?
                      <Link
                        onClick={() => navigate("/login")}
                        style={{ cursor: "pointer", fontWeight: 800 }}
                      >
                        {` Ingresa aquí `}
                      </Link>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} container justify="center">
                    <Button
                      disabled={isButtonDisabled}
                      isSubmit
                      size="small"
                      color="gradient"
                    >
                      Registrarse
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid>
            <Typography style={{ marginTop: 24, fontSize: 12 }}>
              Registro privado, contactate con Alchtec para activar tu cuenta.
            </Typography>
          </Grid>
        )
      ) : (
        <Grid
          style={{ height: "30vh" }}
          justify="center"
          alignContent="center"
          container
        >
          <CircularProgress />
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {LOGIN_ERROR_MESSAGES[errorCode]}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default RegisterForm
