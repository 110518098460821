import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Check } from "@material-ui/icons"

const EmailVerifiedMessage = () => {
  return (
    <Grid container justify="center">
      <Check color="secondary" style={{ paddingTop: "24px" }} />
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          Tu cuenta ha sido verificada con exíto!
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EmailVerifiedMessage
