import React from "react"
import { ThemeProvider } from "@material-ui/core"
import AuthPage from "../components/AuthPage"
import { AuthProvider } from "../context/auth"
import theme from "../context/theme"
import RegisterForm from "../components/RegisterForm"

const LandingContent = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AuthPage content={RegisterForm} />
      </AuthProvider>
    </ThemeProvider>
  )
}

export default LandingContent
